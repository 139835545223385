import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Role } from "src/app/shared/models";

export interface IMenuItem {
  id?: string;
  title?: string;
  description?: string;
  type: string; // Possible values: link/dropDown/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  active?: boolean;
}
export interface IChildItem {
  id?: string;
  parentId?: string;
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
  active?: boolean;
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

interface ISidebarState {
  sidenavOpen?: boolean;
  childnavOpen?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  public sidebarState: ISidebarState = {
    sidenavOpen: true,
    childnavOpen: false,
  };
  constructor() {}

  defaultMenu: IMenuItem[] = [
    {
      name: "Dashboard",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      type: "link",
      icon: "i-Bar-Chart",
      state: "/sp/dashboard",
    },
    {
      name: "Branches",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      type: "dropDown",
      icon: "i-Library",
      sub: [
        {
          icon: "i-Windows-2",
          name: "Branches List",
          state: "/sp/branches/list",
          type: "link",
        },
        {
          icon: "i-Add",
          name: "Add New Branch",
          state: "/sp/branches/add",
          type: "link",
        },
      ],
    },
    {
      name: "Templates",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      type: "dropDown",
      icon: "i-Globe",
      sub: [
        {
          icon: "i-Windows-2",
          name: "Short Trips",
          state: "/sp/trips/normaliaztion",
          type: "link",
        },
        {
          icon: "i-Windows-2",
          name: "Liveaboard Trips",
          state: "/sp/trips/normaliaztion",
          type: "link",
        },
        {
          icon: "i-Windows-2",
          name: "Extra Services",
          state: "/sp/trips/Services",
          type: "link",
        },
        {
          icon: "i-Windows-2",
          name: "Terms and Conditions",
          state: "/sp/trips/TermsConditions",
          type: "link",
        },
      ],
    },
    {
      name: "Trips",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      type: "dropDown",
      icon: "i-Globe",
      sub: [
        {
          icon: "i-Windows-2",
          name: "Trips List",
          state: "/sp/trips/list",
          type: "link",
        },
        {
          icon: "i-Add",
          name: "Add New Trip",
          state: "/sp/trips/add",
          type: "link",
        },
      ],
    },
    {
      name: "Liveaboard",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      type: "dropDown",
      icon: "i-Compass-2",
      sub: [
        {
          icon: "i-Windows-2",
          name: "Liveaboard Trips List",
          state: "/sp/liveaboard/list",
          type: "link",
        },
        {
          icon: "i-Add",
          name: "Add New Trip",
          state: "/sp/liveaboard/add",
          type: "link",
        },
      ],
    },

    {
      name: "Courses",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      type: "dropDown",
      icon: "i-University1",
      sub: [
        {
          icon: "i-Windows-2",
          name: "Courses List",
          state: "/sp/courses/list",
          type: "link",
        },
        {
          icon: "i-Add",
          name: "Add New Course",
          state: "/sp/courses/add",
          type: "link",
        },
      ],
    },
    {
      name: "Financial",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      type: "dropDown",
      icon: "i-Coins",
      sub: [
        {
          icon: "i-Windows-2",
          name: "Reservations List",
          state: "/sp/financial/reservations",
          type: "link",
        },
        {
          icon: "i-Windows-2",
          name: "Cancel requests",
          state: "/sp/financial/cancel-requests",
          type: "link",
        },
        {
          icon: "i-Windows-2",
          name: "Ledger List",
          state: "/sp/financial/ledger",
          type: "link",
        },
        // { icon: 'i-Add', name: 'Add New Package', state: '/sp/packages/add', type: 'link' }
      ],
    },
    {
      name: "Resources",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      type: "dropDown",
      icon: "i-Coins",
      sub: [
        {
          icon: "i-Windows-2",
          name: "Promocodes",
          state: "/sp/resource/promocode",
          type: "link",
        },
      ],
    },
  ];

  adminMenu: IMenuItem[] = [
    {
      name: "Dashboard",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      type: "link",
      icon: "i-Bar-Chart",
      state: "/admin/dashboard",
    },
    {
      name: "Users",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      type: "dropDown",
      icon: "i-Business-Mens",
      sub: [
        {
          icon: "i-Windows-2",
          name: "Providers List",
          state: "/admin/users/providers",
          type: "link",
        },
        {
          icon: "i-Windows-2",
          name: "Customers List",
          state: "/admin/users/customers",
          type: "link",
        },
      ],
    },
    {
      name: "Branches",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      type: "dropDown",
      icon: "i-Library",
      sub: [
        {
          icon: "i-Windows-2",
          name: "Branches List",
          state: "/admin/branches/list",
          type: "link",
        },
      ],
    },
    {
      name: "Organizations",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      type: "dropDown",
      icon: "i-Globe",
      sub: [
        {
          icon: "i-Windows-2",
          name: "Organizations List",
          state: "/admin/organizations/list",
          type: "link",
        },
        {
          icon: "i-Add",
          name: "Add New Organization",
          state: "/admin/organizations/add",
          type: "link",
        },
      ],
    },
    {
      name: "Liveaboard",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      type: "dropDown",
      icon: "i-Compass-2",
      sub: [
        {
          icon: "i-Windows-2",
          name: "Liveaboard List",
          state: "/admin/liveaboard/list",
          type: "link",
        },
        {
          icon: "i-Add",
          name: "Add New Liveaboard",
          state: "/admin/liveaboard/add",
          type: "link",
        },
      ],
    },
    {
      name: "Financial",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      type: "dropDown",
      icon: "i-Coins",
      sub: [
        {
          icon: "i-Windows-2",
          name: "General ledger",
          state: "/admin/financial/ledger",
          type: "link",
        },
        {
          icon: "i-Windows-2",
          name: "Reservations List",
          state: "/admin/financial/reservations",
          type: "link",
        },
        {
          icon: "i-Windows-2",
          name: "Free Cancelation Reservations List",
          state: "/admin/financial/freecancelation-reservations",
          type: "link",
        },
        {
          icon: "i-Windows-2",
          name: "Cancel requests",
          state: "/admin/financial/cancel-requests",
          type: "link",
        },
      ],
    },
    {
      name: "Setting",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      type: "dropDown",
      icon: "i-Gears",
      sub: [
        {
          icon: "i-Windows-2",
          name: "General Settings",
          state: "/admin/settings/general",
          type: "link",
        },
        {
          icon: "i-Windows-2",
          name: "Countries List",
          state: "/admin/settings/countries",
          type: "link",
        },
        {
          icon: "i-Windows-2",
          name: "Amenities List",
          state: "/admin/settings/amenities",
          type: "link",
        },
        {
          icon: "i-Windows-2",
          name: "Equipments List",
          state: "/admin/settings/equipments",
          type: "link",
        },
        {
          icon: "i-Windows-2",
          name: "Notifications List",
          state: "/admin/settings/notifications",
          type: "link",
        },
        {
          icon: "i-Windows-2",
          name: "Reviews List",
          state: "/admin/settings/reviews",
          type: "link",
        },
        {
          icon: "i-Windows-2",
          name: "Diver to SP",
          state: "/admin/settings/diver-to-sp",
          type: "link",
        },
        {
          icon: "i-Windows-2",
          name: "Subscribers",
          state: "/admin/settings/subscribers",
          type: "link",
        },
        {
          icon: "i-Windows-2",
          name: "CMS Pages",
          state: "/admin/settings/staticpages",
          type: "link",
        },
        {
          icon: "i-Windows-2",
          name: "AppSetting Keys",
          state: "/admin/settings/appSettingKeys",
          type: "link",
        },
      ],
    },
    {
      name: "resource",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      type: "dropDown",
      icon: "i-Coins",
      sub: [
        {
          icon: "i-Windows-2",
          name: "resource List",
          state: "/admin/resource/promocode",
          type: "link",
        },
        {
          icon: "i-Windows-2",
          name: "Explore Banners",
          state: "/admin/resource/explore",
          type: "link",
        },
      ],
    },
  ];

  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // You can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    switch (menuType) {
      case Role.Admin:
        this.menuItems.next(this.adminMenu);
        break;
      case Role.BusinessOwner:
        this.menuItems.next(this.defaultMenu);
        break;
      default:
        this.menuItems.next(this.defaultMenu);
    }
  }
}
