import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'bootDash';
  currentUser: any;
  menuLinks;
  snipping;

  // Auto logout varibles
  MINUTES_UNITL_AUTO_LOGOUT = 30 // in mins
  CHECK_INTERVAL = 1000 // in ms
  lastAction;

  constructor(
    private router: Router,
    private authService: AuthService
  ) {
    this.authService.currentUser.subscribe(x => this.currentUser = x);

    if (this.currentUser) {

      // Fire Auto Logout Functions
      this.reset();
      this.check();
      this.initListener();
      this.initInterval();
    }

  }


  initListener() {
    document.body.addEventListener('click', () => this.reset());
  }

  reset() {
    this.lastAction = Date.now();
    localStorage.setItem('lastAction', this.lastAction);
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, this.CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeleft = this.lastAction + this.MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (isTimeout) {
      this.logout();
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/']);
  }
}
