import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { AdminLayoutSidebarLargeComponent } from './shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component';
import { Role } from './shared/models';

const adminRoutes: Routes = [
  {
    path: 'resource',
    loadChildren: () => import('./views/resource/resource.module').then(m => m.ResourceModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'branches',
    loadChildren: () => import('./views/admin-branches/admin-branches.module').then(m => m.AdminBranchesModule)
  },
  {
    path: 'trips',
    loadChildren: () => import('./views/trips/trips.module').then(m => m.TripsModule)
  },
  {
    path: 'courses',
    loadChildren: () => import('./views/courses/courses.module').then(m => m.CoursesModule)
  },
  {
    path: 'organizations',
    loadChildren: () => import('./views/admin-organizations/admin-organizations.module').then(m => m.AdminOrganizationsModule)
  },
  {
    path: 'liveaboard',
    loadChildren: () => import('./views/liveaboard/liveaboard.module').then(m => m.LiveaboardModule)
  },
  {
    path: 'liveaboard-trips',
    loadChildren: () => import('./views/liveaboard-trips/liveaboard-trips.module').then(m => m.LiveaboardTripsModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'financial',
    loadChildren: () => import('./views/admin-financial/admin-financial.module').then(m => m.AdminFinancialModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./views/setting/setting.module').then(m => m.SettingModule)
  }
];

const busnissOnerRoutes: Routes = [
  {
    path: 'resource',
    loadChildren: () => import('./views/resource/resource.module').then(m => m.ResourceModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'branches',
    loadChildren: () => import('./views/branches/branches.module').then(m => m.BranchesModule)
  },
  {
    path: 'trips',
    loadChildren: () => import('./views/trips/trips.module').then(m => m.TripsModule)
  },
  {
    path: 'courses',
    loadChildren: () => import('./views/courses/courses.module').then(m => m.CoursesModule)
  },
  {
    path: 'liveaboard',
    loadChildren: () => import('./views/liveaboard-trips/liveaboard-trips.module').then(m => m.LiveaboardTripsModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'financial',
    loadChildren: () => import('./views/financial/financial.module').then(m => m.FinancialModule)
  }
];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'signup',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
      }
    ]
  },

  {
    path: 'admin',
    component: AdminLayoutSidebarLargeComponent,
    canActivate: [AuthGaurd],
    data: { roles: [Role.Admin] },
    children: adminRoutes
  },
  {
    path: 'sp',
    component: AdminLayoutSidebarLargeComponent,
    canActivate: [AuthGaurd],
    data: { roles: [Role.BusinessOwner] },
    children: busnissOnerRoutes
  },
  {
    path: '**',
    redirectTo: 'others/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
