import { Injectable, EventEmitter, Output } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { User } from '../../shared/models';
const act = localStorage.getItem('cUserDivehood') ? JSON.parse(localStorage.getItem('cUserDivehood'))['data'].accessToken : 'no';
const refreshToken = localStorage.getItem('cUserDivehood') ? JSON.parse(localStorage.getItem('cUserDivehood'))['data'].refreshToken : 'no';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  act;
  refreshToken;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${act}`
    })
  };

  refreshTokenHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${refreshToken}`
    })
  };

  httpOptionsUploadFiles = {
    headers: new HttpHeaders({
      'enctype': 'multipart/form-data',
      'Authorization': `Bearer ${act}`
    })
  };

  invokeEvent: Subject<any> = new Subject();
  @Output() change: EventEmitter<boolean> = new EventEmitter();


  callMethodOfSecondComponent() {
    this.invokeEvent.next('divehoodMethods');
  }

  toggleVerify() {
    this.invokeEvent.next('toggleVerify');
  }

  constructor(private store: LocalStoreService, private http: HttpClient) {
    this.checkAuth();
  }

  // BackendUsers/CheckIsSuspended
  checkIsSuspended() {
    return this.http.get(`${environment.apiUrl}/BackendUsers/CheckIsSuspended`);
  }
  checkAuth() {
    this.currentUserSubject = new BehaviorSubject<any>(this.store.getItem('cUserDivehood'));
    this.currentUser = this.currentUserSubject.asObservable();
  }


  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  get windowRef() {
    return window
  }

  signin(credentials) {
    return this.http.post<any>(`${environment.apiUrl}/BackendUsers/Login?lang=en`, credentials)
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user['data'].accessToken) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('cUserDivehood', JSON.stringify(user));
          this.currentUserSubject.next(user);

          // Save AccessToken in LocalStorage
          this.act = 'Bearer ' + user['data'].accessToken;
          this.httpOptions.headers = this.httpOptions.headers.set('authorization', this.act);

          // Save refreshToken in LocalStorage
          this.refreshToken = 'Bearer ' + user['data'].refreshToken;
          this.refreshTokenHttpOptions.headers = this.refreshTokenHttpOptions.headers.set('authorization', this.refreshToken);
        }
        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    this.store.clear();
    this.currentUserSubject.next(null);
    window.location.href = "/";
  }

  // POST BackendUsers/CheckMobileAndEmail  STEP #1
  checkMobileAndEmail(data) {
    return this.http.post(`${environment.apiUrl}/BackendUsers/CheckMobileAndEmail?lang=en`, data);
  }

  // Firebase checker and verfied STEP #2
  // BackendUsers/Register?lang=en STEP #3
  register(user) {
    return this.http.post<any>(`${environment.apiUrl}/BackendUsers/Register?lang=en`, user)
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user['data'].accessToken) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('cUserDivehood', JSON.stringify(user));
          this.currentUserSubject.next(user);

          // Save AccessToken in LocalStorage
          this.act = 'Bearer ' + user['data'].accessToken;
          this.httpOptions.headers = this.httpOptions.headers.set('authorization', this.act);

          // Save refreshToken in LocalStorage
          this.refreshToken = 'Bearer ' + user['data'].refreshToken;
          this.refreshTokenHttpOptions.headers = this.refreshTokenHttpOptions.headers.set('authorization', this.refreshToken);
        }
        return user;
      }));
  }

  forget(email) {
    return this.http.post(`${environment.apiUrl}/BackendUsers/SendPasswordResetLink?lang=en`, email);
  }

  resetPassword(email, id) {
    return this.http.post(`${environment.apiUrl}/BackendUsers/ResetPassword/${id}?lang=en`, email);
  }

  getProfileData() {
    return this.http.get(`${environment.apiUrl}/BackendUsers/GetProfileData`, this.httpOptions);
  }

  updateProfileData(data) {
    return this.http.post<any>(`${environment.apiUrl}/BackendUsers/UpdateProfile`, data, this.httpOptions)
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user['data'].accessToken) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('cUserDivehood', JSON.stringify(user));
          this.currentUserSubject.next(user);

          // Save AccessToken in LocalStorage
          this.act = 'Bearer ' + user['data'].accessToken;
          this.httpOptions.headers = this.httpOptions.headers.set('authorization', this.act);
        }
        return user;
      }));
  }

  updateProfileImage(file) {
    return this.http.post(`${environment.apiUrl}/BackendUsers/updatelogo`, file, this.httpOptionsUploadFiles);
  }

  checkMobile(phoneNumber) {
    return this.http.post(`${environment.apiUrl}/BackendUsers/CheckMobile?lang=en`, phoneNumber);
  }

  verifyMobile(phoneNumber) {
    return this.http.post<any>(`${environment.apiUrl}/BackendUsers/ConfirmMobile?lang=en`, phoneNumber)
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user['data'].accessToken) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('cUserDivehood', JSON.stringify(user));
          this.currentUserSubject.next(user);

          // Save AccessToken in LocalStorage
          this.act = 'Bearer ' + user['data'].accessToken;
          this.httpOptions.headers = this.httpOptions.headers.set('authorization', this.act);
        }
        return user;
      }));
  }

  refreshTokenFun() {
    return this.http.post<any>(`${environment.apiUrl}/BackendUsers/RefreshTokens`, this.refreshTokenHttpOptions)
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user['data'].accessToken) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('cUserDivehood', JSON.stringify(user));
          this.currentUserSubject.next(user);

          // Save AccessToken in LocalStorage
          this.act = 'Bearer ' + user['data'].accessToken;
          this.httpOptions.headers = this.httpOptions.headers.set('authorization', this.act);

          // Save refreshToken in LocalStorage
          this.refreshToken = 'Bearer ' + user['data'].refreshToken;
          this.refreshTokenHttpOptions.headers = this.refreshTokenHttpOptions.headers.set('authorization', this.refreshToken);
        }
        return user;
      }));
  }

  // POST /api/ios/v1/BackendUsers/ChangeMobile
  updateMobilePhone(mobile) {
    return this.http
      .post<any>(`${environment.apiUrl}/BackendUsers/ChangeMobile`, mobile, this.httpOptions)
  }

  // POST /api/ios/v1/BackendUsers/ChangePassword
  changePassword(passwords) {
    return this.http.post(`${environment.apiUrl}/BackendUsers/ChangePassword`, passwords, this.httpOptions);
  }


  // api/ios/v1/Users/ConfirmMobile?Lang=en&Mobile=00201020875298&Code=5555
  confirmMobile(lang, mobile, code) {
    return this.http.get<any>(`${environment.apiUrl}/api/ios/v1/Users/ConfirmMobile?Lang=${lang}&Mobile=${mobile}&Code=${code}`)
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user['data'].accessToken) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('cUserDivehood', JSON.stringify(user));
          this.currentUserSubject.next(user);

          // Save AccessToken in LocalStorage
          this.act = 'Bearer ' + user['data'].accessToken;
          this.httpOptions.headers = this.httpOptions.headers.set('authorization', this.act);
        }
        return user;
      }));
  }

  // /api/ios/v1/Users/SendConfirmationCode?Lang=en&Mobile=222
  sendConfirmationCode(lang, mobile) {
    return this.http
      .get(`${environment.apiUrl}/Users/SendConfirmationCode?Lang=${lang}&Mobile=${mobile}`);
  }

  // /api/ios/v1/Users/SendConfirmationCode
  sendConfirmationCodeToEmail() {
    return this.http
      .get(`${environment.apiUrl}/Users/SendConfirmationCode`, this.httpOptions);
  }

  // v1/Users/ConfirmEmail?Code=
  confirmEmail(code) {
    return this.http.get<any>(`${environment.apiUrl}/Users/ConfirmEmail?Code=${code}`, this.httpOptions)
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user['data'].accessToken) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('cUserDivehood', JSON.stringify(user));
          this.currentUserSubject.next(user);

          // Save AccessToken in LocalStorage
          this.act = 'Bearer ' + user['data'].accessToken;
          this.httpOptions.headers = this.httpOptions.headers.set('authorization', this.act);
        }
        return user;
      }));
  }

}


