import { Component, OnInit, ElementRef, HostListener, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../../../services/navigation.service';
import { SearchService } from '../../../../services/search.service';
import { AuthService } from '../../../../services/auth.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { stringify } from '@angular/compiler/src/util';
import { BranchesService } from 'src/app/shared/services/branches.service';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import { Role } from 'src/app/shared/models';

@Component({
  selector: 'app-header-sidebar-large',
  templateUrl: './header-sidebar-large.component.html',
  styleUrls: ['./header-sidebar-large.component.scss'],
  animations: [SharedAnimations]
})
export class HeaderSidebarLargeComponent implements OnInit {
  error = '';
  profileData: any = {};
  notifications: any[];
  profilePath: string;
  newbranches: any[] = [];
  mainAlert = true;
  isVerified = true;
  isActive = true;


  constructor(
    private navService: NavigationService,
    public searchService: SearchService,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private branchesService: BranchesService,
    private eRef: ElementRef
  ) {
    this.getProfileData();

    this.auth.invokeEvent.subscribe(value => {
      if (value === 'divehoodMethods') {
        this.getProfileData();
      }
    });

    this.branchesService.invokeEvent.subscribe(value => {
      if (value === 'divehoodMethods') {
        this.getNewBranchesList();
      }
    });

    // /pages/profile
    if (this.auth.currentUserValue) {
      this.auth.currentUser.subscribe(res => {
        if (res['data'].role === Role.BusinessOwner) {
          this.profilePath = '/sp/pages/profile';
        } else if (res['data'].role === Role.Admin) {
          this.profilePath = '/admin/pages/profile';
          setTimeout(() => {
            this.getNewBranchesList();
          }, 1000);
        } else {
          this.router.navigate(['/']);
        }
      });
    }
  }

  @HostListener('document:click')
  clickout() {
    const userObj = JSON.parse(localStorage.getItem('cUserDivehood'));
    if (localStorage.getItem('cUserDivehood') == null) {
      window.location.href = "/";
    } else if (userObj['data'].role !== this.route.snapshot.data.roles[0]) {
      window.location.href = "/";
    }
  }

  ngOnInit() {
    this.getProfileData();
    this.auth.checkIsSuspended().subscribe(res => {
      //
    },
      err => {
        this.toastr.error(err.error['responseMessage'], 'Error!',
          { timeOut: 3000, closeButton: true, progressBar: true, positionClass: 'toast-center-center' });
        this.auth.logout();
      });
  }

  toggelSidebar() {
    const state = this.navService.sidebarState;
    if (state.childnavOpen && state.sidenavOpen) {
      return state.childnavOpen = false;
    }
    if (!state.childnavOpen && state.sidenavOpen) {
      return state.sidenavOpen = false;
    }
    if (!state.sidenavOpen && !state.childnavOpen) {
      state.sidenavOpen = true;
      setTimeout(() => {
        state.childnavOpen = true;
      }, 50);
    }
  }

  // Get Profile Data
  getProfileData() {
    this.auth.getProfileData()
      .pipe(first())
      .subscribe(
        res => {
          this.profileData = res['data'];
          // console.log(res);
          this.isVerified = res['data'].isVerified;
          this.isActive = res['data'].isActive;
        },
        error => {
          this.error = error;
          if (error.status === 401) {
            this.auth.refreshTokenFun().pipe(first())
              .subscribe(result => {
                return true;
              },
                err => {
                  this.signout();
                });
          }
        });
  }

  signout() {
    this.auth.logout();
  }

  // Get Branches List for Super admin
  getNewBranchesList() {
    // this.branchesService.getNewList()
    //   .subscribe((res: any[]) => {
    //     let list = res['data'];
    //     this.newbranches = [...list];
    //   });
  }

}
