import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {
  Lang: string = localStorage.getItem('divehoodLang') ? localStorage.getItem('divehoodLang') : 'en'

  constructor(public translate: TranslateService) {
    // this.Lang = (typeof (Storage) !== "undefined") ? localStorage.getItem('divehoodLang') : 'en';
    if(localStorage.getItem('divehoodLang')) {
      localStorage.setItem('divehoodLang', 'en');
      // console.log(localStorage.getItem('divehoodLang'));
    }
    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');

    this.changeLang(this.Lang);

  }

  changeLang(lang) {
    this.translate.use(lang);
    localStorage.setItem('divehoodLang', lang);
    this.Lang = lang;
  }

  ngOnInit() {
  }

}
