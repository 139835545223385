import { FormGroup, ValidatorFn } from '@angular/forms';
import * as XLSX from 'xlsx';

export class Utils {

    static numericOnly(event): boolean {
        // noinspection JSDeprecatedSymbols
        const charCode = (event.which) ? event.which : event.key || event.keyCode;  // keyCode is deprecated but needed for some browsers
        return !(charCode === 101 || charCode === 69 || charCode === 45 || charCode === 43);
    }

    static requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
        return function validate(formGroup: FormGroup) {
            let checked = 0;

            Object.keys(formGroup.controls).forEach(key => {
                const control = formGroup.controls[key];

                if (control.value === true) {
                    checked++;
                }
            });

            if (checked < minRequired) {
                return {
                    requireCheckboxesToBeChecked: true,
                };
            }

            return null;
        }
    }

    static numberWithCommas(x) {
        const digit = x.toString().split(',').join('');
        const parts = digit.toString().split('.');
        let priceWithcomma;

        if (parts[1] !== undefined) {
            priceWithcomma = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + parts[1];
        } else {
            priceWithcomma = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return [digit, priceWithcomma];
    }
    static isMobile() {
        return window && window.matchMedia('(max-width: 767px)').matches;
    }
    static ngbDateToDate(ngbDate: { month, day, year }) {
        if (!ngbDate) {
            return null;
        }
        return new Date(`${ngbDate.month}/${ngbDate.day}/${ngbDate.year}`);
    }
    static dateToNgbDate(date: Date) {
        if (!date) {
            return null;
        }
        date = new Date(date);
        return { month: date.getMonth() + 1, day: date.getDate(), year: date.getFullYear() };
    }
    static scrollToTop(selector: string) {
        if (document) {
            const element = <HTMLElement>document.querySelector(selector);
            element.scrollTop = 0;
        }
    }
    static genId() {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }


    static copyClipboard() {
        var textArea,
            copy;

        function isOS() {
            return navigator.userAgent.match(/ipad|iphone/i);
        }

        function createTextArea(text) {
            textArea = document.createElement('textArea');
            textArea.value = text;
            document.body.appendChild(textArea);
        }

        function selectText() {
            var range,
                selection;

            if (isOS()) {
                range = document.createRange();
                range.selectNodeContents(textArea);
                selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
                textArea.setSelectionRange(0, 999999);
            } else {
                textArea.select();
            }
        }

        function copyToClipboard() {
            document.execCommand('copy');
            document.body.removeChild(textArea);
        }

        copy = function (text) {
            createTextArea(text);
            selectText();
            copyToClipboard();
            navigator.clipboard.writeText(text).then(function () {
                /* clipboard successfully set */
                // alert('Copying text command was success!');
            }, function () {
                /* clipboard write failed */
                // alert('Your browser does not support copying feature.\nYou can copy it manualy.\nMessage: \n' + text);
            });
        };
        return {
            copy: copy
        };
    };

    static copyStore(text) {
        localStorage.setItem('divhood-copy-store', text);

        let copiedString = localStorage.getItem('divhood-copy-store');
        console.log(JSON.stringify(copiedString));
        document.execCommand(copiedString);
    }

    static exportexcel(tableID, itemsName) {
        const currentDate = new Date();

        /* table id is passed over here */
        const element = document.getElementById(tableID);
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        const fileName = itemsName + currentDate.getDay() + currentDate.getMonth()
            + currentDate.getFullYear() + '_' + currentDate.getSeconds() + '.xlsx';
        /* save to file */
        XLSX.writeFile(wb, fileName);

    }
}
