import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
const act = localStorage.getItem("cUserDivehood")
  ? JSON.parse(localStorage.getItem("cUserDivehood"))["data"].accessToken
  : "no";

@Injectable({
  providedIn: "root",
})
export class HelperService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${act}`,
    }),
  };

  httpOptionsUploadFiles = {
    headers: new HttpHeaders({
      enctype: "multipart/form-data",
      Authorization: `Bearer ${act}`,
    }),
  };
  errorData: { errorTitle: string; errorDesc: string };
  constructor(private http: HttpClient) {}

  // GetCountries
  getList() {
    return this.http.get(
      `${environment.apiUrl}/SPGeneral/GetCountries`,
      this.httpOptions
    );
  }

  // GetCities
  getCountryById(id) {
    return this.http.get(
      `${environment.apiUrl}/SPGeneral/GetCities?countryId=${id}`,
      this.httpOptions
    );
  }

  // http://divehood.revival.one/api/ios/v1/BackendDashboard/GetGraphsData
  getDashboardReports(currency, isPayment) {
    return this.http.get(
      `${environment.apiUrl}/BackendDashboard/GetGraphsData?Currency=${currency}&IncludePaymentTypeValues=${isPayment}`,
      this.httpOptions
    );
  }

  // Equipments functions
  // /api/ios/v1/SAEquipments/Get
  getEquipments() {
    return this.http.get(`${environment.apiUrl}/SAEquipments/Get`);
  }

  // SuperAdminEquipments/Add
  addEquipment(requestData: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/SAEquipments/Add`,
      requestData,
      this.httpOptionsUploadFiles
    );
  }

  // Notifications functions
  // /SAPushNotifications/Get?pageNo=0&limit=20&SearchQuery=a
  getNotifications(pageNo, limit, key) {
    return this.http.get(
      `${environment.apiUrl}/SAPushNotifications/Get?pageNo=${pageNo}&limit=${limit}&SearchQuery=${key}`
    );
  }

  // SuperAdminPushNotifications/Add
  addNotification(requestData: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/SAPushNotifications/Add`,
      requestData
    );
  }

  delete(id: any) {
    return this.http.get(
      `${environment.apiUrl}/SAPushNotifications/Delete?notificationId=${id}`,
      this.httpOptions
    );
  }

  // Reviews functions
  // /SAReviews/Get?pageNo=0&limit=20&SearchQuery=a
  getReviews(pageNo, limit, key) {
    return this.http.get(
      `${environment.apiUrl}/SAReviews/Get?pageNo=${pageNo}&limit=${limit}&SearchQuery=${key}`
    );
  }
  deleteReview(id: any) {
    return this.http.get(
      `${environment.apiUrl}/SAReviews/Delete?reviewId=${id}`,
      this.httpOptions
    );
  }

  // SuperAdminEquipments/Update
  updateEquipment(requestData: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/SAEquipments/Update`,
      requestData,
      this.httpOptionsUploadFiles
    );
  }

  // SuperAdminEquipments/GetById?EquipmentId=2
  getEquipment(EquipmentId) {
    return this.http.get(
      `${environment.apiUrl}/SAEquipments/GetById?EquipmentId=${EquipmentId}`
    );
  }

  // SuperAdminEquipments/Delete?EquipmentId==0
  deleteEquipment(EquipmentId) {
    return this.http.post<any>(
      `${environment.apiUrl}/SAEquipments/Delete?EquipmentId=${EquipmentId}`,
      EquipmentId
    );
  }

  // api/ios/v1/SASettings/Get
  getAdminSettings() {
    return this.http.get(`${environment.apiUrl}/SASettings/Get`);
  }

  getSettings() {
    return this.http.get(`${environment.apiUrl}/SPGeneral/GetSettings`);
  }

  // /api/ios/v1/SASettings/Update
  updateAdminSettings(data) {
    return this.http.post<any>(`${environment.apiUrl}/SASettings/Update`, data);
  }

  // POST /SAUsers/ConvertDiverToSP
  convertDiverToSP(email, data) {
    return this.http.post<any>(
      `${environment.apiUrl}/SAUsers/ConvertDiverToSP?email=${email}`,
      data
    );
  }

  // POST /api/back/v1/SPCourses/Dublicate
  // POST /api/back/v1/SPTrips/Dublicate
  dublicateSPCourses(data, type) {
    let itemType;
    if (type == "courses") {
      itemType = "SPCourses";
    } else {
      itemType = "SPTrips";
    }
    return this.http.post<any>(
      `${environment.apiUrl}/${itemType}/Dublicate`,
      data
    );
  }

  // Bitly API's
  // https://api-ssl.bitly.com/v4/groups
  getGroupID() {
    return this.http.get(`https://api-ssl.bitly.com/v4/groups`);
  }

  // https://api-ssl.bitly.com/v4/shorten
  shorten(link) {
    const data = {
      long_url: link,
      domain: "bit.ly",
      group_guid: "Ba1bc23dE4F",
    };
    return this.http.post<any>(`https://api-ssl.bitly.com/v4/shorten`, data, {
      headers: new HttpHeaders({
        Authorization: "Bearer 4438e7d92bc7ff41143c54d9a7ffcde5a0a96e14",
        "Content-Type": "application/json",
      }),
    });
  }
}
