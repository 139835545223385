export const environment = {
  production: false,
  //apiUrl: "http://localhost:51377/api/back/v1",
  apiUrl: "https://api.divehood.com/api/back/v1",
  //apiUrl: "https://app-api-divehood-dev-uaen-001.azurewebsites.net/api/back/v1",

  firebase: {
    apiKey: "AIzaSyCekG6vpfTtGjbOs1M0olY_tN4OLYlO6FU",
    authDomain: "divehood.firebaseapp.com",
    databaseURL: "https://divehood.firebaseio.com",
    projectId: "divehood",
    storageBucket: "divehood.appspot.com",
    messagingSenderId: "618154071400",
    appId: "1:618154071400:web:031d6b8298575ceb07f2dd",
    measurementId: "G-Z3HVHCDJJY",
  },
};
