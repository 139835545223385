import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
const act = localStorage.getItem('cUserDivehood') ? JSON.parse(localStorage.getItem('cUserDivehood'))['data'].accessToken : 'no';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  public searchOpen: boolean;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${act}`
    })
  };
  constructor(private http: HttpClient) { }

  // http://divehood.revival.one/api/ios/v1/SPGeneral/Search?searchKey=a
  getSearchResults(searchTerm) {
    return this.http.get<any>(`${environment.apiUrl}/api/ios/v1/SPGeneral/Search?searchKey=${searchTerm}`, this.httpOptions); 
  }
}
