import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { LocalStoreService } from "./local-store.service";
import { NavigationService } from "./navigation.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGaurd implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthService,
    private store: LocalStoreService,
    private navigationService: NavigationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.auth.currentUserValue;
    if (currentUser) {

      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(currentUser['data'].role) === -1) {
        // role not authorised so redirect to home page
        // this.store.clear();
        this.router.navigate(['/']);
        return false;
      }

      // Choose navigation bar based on role
      this.navigationService.publishNavigationChange(route.data.roles[0]);

      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/signin'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
