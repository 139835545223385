import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { HelperService } from "../../services/helper.service";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormArray,
  FormControl,
} from "@angular/forms";

@Component({
  selector: "app-dublicate-item",
  templateUrl: "./dublicate-item.component.html",
  styleUrls: ["./dublicate-item.component.scss"],
})
export class DublicateItemComponent implements OnInit {
  @Input("dublicateItemID") dublicateItemID: object;
  @Input("type") type: string;

  dublicateForm: FormGroup;
  @ViewChild("dublicateFormModel", { static: true })
  dublicateFormModel: ElementRef;
  submitted = false;
  loading = false;
  loadingText: string;

  // For example, an array of choices
  public days: Array<any> = [
    "Saturday",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
  ];

  constructor(
    private helperService: HelperService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.buildAddForm();
  }

  openLg(content, id) {
    this.modalService.open(content, { size: "lg" });
    const formArray: FormArray = this.dublicateForm.get("days") as FormArray;

    this.days.forEach((el, index) => {
      formArray.push(new FormControl(el));
    });
  }

  buildAddForm() {
    this.dublicateForm = this.fb.group({
      copiedId: this.dublicateItemID,
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      days: new FormArray([]),
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.dublicateForm.controls;
  }

  submitDublicateForm() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.dublicateForm.invalid) {
      return;
    }

    this.loading = true;
    this.loadingText = "Adding...";
    this.dublicateForm.controls["copiedId"].setValue(this.dublicateItemID);

    this.dublicateForm.controls["startDate"].setValue(
      `${this.dublicateForm.value.startDate["year"]}-${this.dublicateForm.value.startDate["month"]}-${this.dublicateForm.value.startDate["day"]}`
    );
    this.dublicateForm.controls["endDate"].setValue(
      `${this.dublicateForm.value.endDate["year"]}-${this.dublicateForm.value.endDate["month"]}-${this.dublicateForm.value.endDate["day"]}`
    );

    this.helperService
      .dublicateSPCourses(this.dublicateForm.value, this.type)
      .subscribe(
        (res) => {
          this.toastr.success(res["responseMessage"], "Success", {
            timeOut: 3000,
            closeButton: true,
            progressBar: true,
            positionClass: "toast-center-center",
          });
          this.loading = false;
          this.modalService.dismissAll();
          location.reload();
        },
        (error) => {
          this.loading = false;
          this.toastr.error(error.error["responseMessage"], "Error!", {
            timeOut: 10000,
            closeButton: true,
            progressBar: true,
            positionClass: "toast-center-center",
          });
          this.modalService.dismissAll();
        }
      );
  }

  dublicateCourses() {}

  onCheckChange(event) {
    const formArray: FormArray = this.dublicateForm.get("days") as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    } else {
      /* unselected */
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
  }
}
