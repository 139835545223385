import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { HelperService } from '../../services/helper.service';
import { Utils } from '../../utils';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-copy-to-clipboard-btn',
  templateUrl: './copy-to-clipboard-btn.component.html',
  styleUrls: ['./copy-to-clipboard-btn.component.scss']
})
export class CopyToClipboardBtnComponent implements OnInit {
  @Input('isItemValid') isItemValid: string;
  @Input('copiedItem') copiedItem: object;
  @Input('type') type: string;
  // Reference copiedTextArea variable inside Component
  @ViewChild('copiedModel', { static: true }) copiedModel: ElementRef;
  copiedTextArea = '';

  constructor(
    private helperService: HelperService,
    private toastr: ToastrService,
    private modalService: NgbModal) { }

  ngOnInit() {
  }
  // bitly integration
  // token 7e375d8bc5371042b0e9c33fe93e3a79419b13a9

  openLg(content, text) {
    this.modalService.open(content, { size: 'lg' });
    this.copiedTextArea = text;
  }

  copyTextAreaToClipBoard(copiedItem) {
    const tripLink = `https://divehood.com/#/${this.type}/${copiedItem.tripId}`;
    const courseLink = `https://divehood.com/#/${this.type}/${copiedItem.courseId}`;
    let itemLink = (this.type == 'courses') ? courseLink : tripLink;
    this.helperService.shorten(itemLink).subscribe(res => {
      let itemTitle = (this.type == 'courses') ? `Course name: ${copiedItem.name}` : `Trip name: ${copiedItem.tripName}`;

      const message = `${itemTitle}
Service provider / Organizer name: ${copiedItem.branchName}
Start date: ${copiedItem.startDate}
Days: ${copiedItem.days} days
City: ${copiedItem.city}
Description: ${copiedItem.description}

Price starts from: ${copiedItem.price} ${copiedItem.currency}

Registration link: ${res.link}

Download the Divehood App:
www.onelink.to/divehood`;

      Utils.copyClipboard().copy(message);
      let toastr = this.toastr;
      let openLg = function () {
        this.openLg(this.copiedModel, message);
      }

      navigator.clipboard.writeText(message).then(() => {
        /* clipboard successfully set */
        toastr.success('Details copied successfully to clipboard!', 'Success',
          { timeOut: 10000, closeButton: true, progressBar: true, positionClass: 'toast-center-center' });
      }, function () {
        /* clipboard write failed */
        alert('Your browser does not support copying feature.\nYou can copy it manualy.\nMessage: \n' + message);
        // openLg();
      });

    });

  }

}
