import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { catchError } from "rxjs/operators";
import { throwError, Subject } from "rxjs";
const act = localStorage.getItem("cUserDivehood")
  ? JSON.parse(localStorage.getItem("cUserDivehood"))["data"].accessToken
  : "no";

@Injectable({
  providedIn: "root",
})
export class BranchesService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  httpOptionsUploadFiles = {
    headers: new HttpHeaders({
      enctype: "multipart/form-data",
      Authorization: `Bearer ${act}`,
    }),
  };
  errorData: { errorTitle: string; errorDesc: string };
  invokeEvent: Subject<any> = new Subject();
  constructor(private http: HttpClient) {}

  // update branches count in header
  updateNewBranchesList() {
    this.invokeEvent.next("divehoodMethods");
  }

  // BackendBranches/GetBranches?SPId=31
  getUserBranches(SPId) {
    return this.http.get(
      `${environment.apiUrl}/BackendBranches/GetBranches?SPId=${SPId}`,
      this.httpOptions
    );
  }

  getBranchesLookups(SPId) {
    return this.http.get(
      `${environment.apiUrl}/BackendBranches/GetBranchesList?SPId=${SPId}`,
      this.httpOptions
    );
  }

  // Get Branches List for Super Admin
  getAllBranches() {
    return this.http.get(
      `${environment.apiUrl}/BackendBranches/GetBranches`,
      this.httpOptions
    );
  }

  //  BackendBranches/GetBranches
  getAllList(spId, branchId, branchEmail, isActive, spName, spMobile, spEmail) {
    let url = `${environment.apiUrl}/BackendBranches/GetBranches?`;
    if (spId) url += `&spId=${spId}`;
    if (branchId) url += `&branchId=${branchId}`;
    if (branchEmail) url += `&branchEmail=${branchEmail}`;

    if (isActive != -1) url += `&isActive=${isActive == "1"}`;
    if (spName) url += `&spName=${spName}`;
    if (spMobile) url += `&spMobile=${spMobile}`;
    if (spEmail) url += `&spEmail=${spEmail}`;

    const last = url.charAt(url.length - 1);
    if (last == "?") url = url.substring(0, url.length - 1);

    return this.http.get(url);
  }

  // Get new Branches List for Super Admin
  getNewList() {
    return this.http.get(
      `${environment.apiUrl}/SABranches/GetNewBranches`,
      this.httpOptions
    );
  }

  // Verify new branches from Super admin
  verifyBranch(id: any) {
    return this.http.post(
      `${environment.apiUrl}/SABranches/VerifyBranch`,
      id,
      this.httpOptions
    );
  }

  // POST /api/ios/v1/SABranches/UpdateCommissionValue
  updateCommission(data: any) {
    return this.http.post(
      `${environment.apiUrl}/SABranches/UpdateCommissionValue`,
      data,
      this.httpOptions
    );
  }

  delete(id: any) {
    return this.http.post(
      `${environment.apiUrl}/BackEndBranches/Delete`,
      id,
      this.httpOptions
    );
  }

  addNew(requestData: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/SPBranches/Add`,
      requestData,
      this.httpOptions
    );
  }

  getItem(id) {
    return this.http.get(
      `${environment.apiUrl}/BackEndBranches/getById?branchId=${id}`,
      this.httpOptions
    );
  }

  update(requestData) {
    return this.http.post<any>(
      `${environment.apiUrl}/BackEndBranches/Update`,
      requestData,
      this.httpOptions
    );
  }

  updateImage(file) {
    return this.http.post(
      `${environment.apiUrl}/BackEndBranches/updatelogo`,
      file,
      this.httpOptionsUploadFiles
    );
  }

  // Images
  getImagesList(itemId) {
    return this.http.get(
      `${environment.apiUrl}/BackEndBranchImages/Get?BranchId=${itemId}`,
      this.httpOptions
    );
  }

  deleteImage(id: any) {
    return this.http.post(
      `${environment.apiUrl}/BackEndBranchImages/Delete`,
      id,
      this.httpOptions
    );
  }

  addNewImage(requestData: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/BackEndBranchImages/Add`,
      requestData,
      this.httpOptionsUploadFiles
    );
  }

  updateItemImage(requestData) {
    return this.http.post<any>(
      `${environment.apiUrl}/BackEndBranchImages/Update`,
      requestData,
      this.httpOptionsUploadFiles
    );
  }

  // Files
  // BackEndBranchFiles/Get?BranchId=
  getFilesList(itemId) {
    return this.http.get(
      `${environment.apiUrl}/BackEndBranchFiles/Get?BranchId=${itemId}`,
      this.httpOptions
    );
  }

  deleteFile(id: any) {
    return this.http.post(
      `${environment.apiUrl}/BackEndBranchFiles/Delete`,
      id,
      this.httpOptions
    );
  }

  addNewFile(requestData: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/BackEndBranchFiles/Add`,
      requestData,
      this.httpOptionsUploadFiles
    );
  }

  updateItemFile(requestData) {
    return this.http.post<any>(
      `${environment.apiUrl}/BackEndBranchFiles/Update`,
      requestData,
      this.httpOptionsUploadFiles
    );
  }

  // Organizations
  viewBranchOrgs(id: any) {
    return this.http.get(
      `${environment.apiUrl}/BackEndOrganizations/GetByBranchId?branchId=${id}`,
      this.httpOptions
    );
  }

  // http://divehood.revival.one/api/ios/v1/BackEndOrganizations/AddForBranch?branchId=26
  updateOrgsForBranch(id, requestData) {
    return this.http.post<any>(
      `${environment.apiUrl}/BackEndOrganizations/AddForBranch?branchId=${id}`,
      requestData,
      this.httpOptionsUploadFiles
    );
  }

  // BackEndBranchImages/UpdateOrder
  updateImagesOrder(requestData) {
    return this.http.post<any>(
      `${environment.apiUrl}/BackEndBranchImages/UpdateOrder`,
      requestData
    );
  }
}
