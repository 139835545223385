import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class FriendlyUrlService {
  originBase = "https://divehood.com";
  constructor(private router: Router) {}

  routeToBranchDetails(compoentName: string, item: any) {
    return `${this.originBase}/${compoentName}/${this.getHyphenatedString(
      item.name.trim()
    )}/${item.branchId}`;
  }

  routeToCourcesDetails(compoentName: string, item: any) {
    return `${this.originBase}/${compoentName}/${this.getHyphenatedString(
      item.name.trim()
    )}/${item.tempId}`;
  }

  routeToLiveaTripDetails(compoentName: string, item: any) {
    let name = item.tripName != null ? item.tripName : item.name;
    return `${this.originBase}/${compoentName}/${this.getHyphenatedString(
      name.trim()
    )}/${item.liveaboardId}`; //liveaboardId tempId
  }

  routeToTripDetails(compoentName: string, item: any) {
    return `${this.originBase}/${compoentName}/${this.getHyphenatedString(
      item.tripName.trim()
    )}/${item.tempId}`;
  }

  private getHyphenatedString(Name) {
    const pattern = /[^a-zA-Z0-9_\s\u0600-\u06FF]/g;
    Name = Name.replace(pattern, "").replaceAll(" ", "-");
    return Name;
  }
}
