import { Role } from "./role";

export class User {
    id: number;
    Name: string;
    Email: string;
    Mobile: string;
    Type: string;
    TypeAR: string;
    lastName: string;
    Bio: string;
    BioAR: string;
    PreferredLang: string;
    LogoURL: string;
    role: Role;
    accessToken: string;
}