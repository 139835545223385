import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FriendlyUrlService } from "../../services/friendly-url.service";

@Component({
  selector: "app-live-preview-btn",
  templateUrl: "./live-preview-btn.component.html",
  styleUrls: ["./live-preview-btn.component.scss"],
})
export class LivePreviewBtnComponent implements OnInit {
  @Input("type") type: string;
  @Input("itemId") itemId: string;
  @Input("isItemValid") isItemValid: string;
  @Input("item") item: any;
  routeBase = "";

  constructor(
    private router: Router,
    private friendlyUrlService: FriendlyUrlService
  ) {}

  ngOnInit() {
    if (this.item != null) {
      if (this.type == "sp") {
        this.routeBase = this.friendlyUrlService.routeToBranchDetails(
          "branch/details",
          this.item
        );
      } else if (this.type == "trips") {
        this.routeBase = this.friendlyUrlService.routeToTripDetails(
          "trips/tripDetails",
          this.item
        );
      } else if (this.type == "courses") {
        this.routeBase = this.friendlyUrlService.routeToCourcesDetails(
          "trips/course",
          this.item
        );
      } else if (this.type == "liveaboard-trip") {
        this.routeBase = this.friendlyUrlService.routeToLiveaTripDetails(
          "trips/liveaboardDetails",
          this.item
        );
      }
    } else {
      let originBase = window.location.origin;
      this.routeBase = `${originBase}\/#\/${this.type}\/${this.itemId}`;
    }
  }

  livePreview(type, itemId) {
    let originBase = window.location.origin;
    this.routeBase = `${originBase}\/#\/${type}\/${itemId}`;
  }
}
