import { Component, OnInit } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, debounceTime, switchMap, map } from 'rxjs/operators';
import { SharedAnimations } from '../../animations/shared-animations';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [SharedAnimations]
})
export class SearchComponent implements OnInit {
  page = 1;
  pageSize = 6;

  results$: Observable<any[]>;
  searchCtrl: FormControl = new FormControl('');
  items: any = [];

  constructor(
    public searchService: SearchService
  ) { }

  ngOnInit() {

    // this.results$ = combineLatest(
    //   this.searchService.getSearchResults(this.searchCtrl),
    //   this.searchCtrl.valueChanges
    //     .pipe(startWith(''), debounceTime(200))
    // )
    //   .pipe(map(([products, searchTerm]) => {
    //     return products['data'].filter(p => {
    //       const titleOrName = (p.name || p.title);
    //       if (titleOrName) {
    //         return titleOrName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
    //       }
    //     });
    //   }));
  }

  search(e) {
    this.searchService.getSearchResults(e).subscribe(res => {
      this.items = res['data'];
    });
  }

}
